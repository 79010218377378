import { round } from 'lodash-es';

type ScheduledPaymentWithAmount = { percent?: number; status: string; amount?: number; };

export default function getAllScheduledPaymentsWithProperAmounts<T extends ScheduledPaymentWithAmount>(
	payments: T[],
	grandTotal: number,
	finalRemaining: number,
): T[] {
	let runningTotal = 0;
	
	return payments.map( ( payment, index ) => {
		if ( payment.status === 'PAID' ) return payment;
	
		if ( index !== payments.length - 1 ) {
			// Calculate amount based on percentage of grand total
			payment.amount = round( ( payment.percent || 0 ) / 100 * grandTotal, 2 );
			runningTotal += payment.amount;
			return payment;
		}
		
		// For the last payment, always use the exact remaining amount
		// This ensures the total always equals the grand total
		payment.amount = round( finalRemaining - runningTotal, 2 );
		
		return payment;
	} );
}
