import CustomCheckBox from '@/components/customCheckBox';
import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import FormattedTextField from '@/components/formattedTextField';
import { useGraphqlResult } from '@/data/query/graphqlProvider';
import { ScheduledPayment } from '@/generated/graphql';
import currencyFormat from '@/helpers/currencyFormat';
import { safeFormatInTimeZone } from '@/helpers/safeFormat';
import getAllScheduledPaymentsWithProperAmounts from '@/modals/scheduledPayments/utils';
import TipsSection from '@/pages/dashboard/commerce/payment/tipsSection';
import { surchargeFeeAtom } from '@/pages/settings/cards';
import type { Order } from '@/types/schema';
import { ArrowBackIos as ArrowBackIosIcon } from '@mui/icons-material';
import {
	Collapse,
	Divider,
	ListItem,
	ListItemButton,
	ListItemText,
	MenuItem,
	Select,
	Stack,
	Typography,
} from '@mui/material';
import BigNumber from 'bignumber.js';
import { useAtomValue } from 'jotai/index';
import { clamp, round, toLower } from 'lodash-es';
import { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	getCardFeeAmount,
	getCashDiscountPercent,
	getOrderTotalDiscountAndFees,
	getPayingTotalAfterCashDiscount,
} from './helpers';

export default function PaymentAmount( { cancelAmount, confirmTotal, method, cardType }: {
	cancelAmount,
	confirmTotal,
	method: string,
	cardType: string
} ) {
	const { t } = useTranslation();
	const invoice = useGraphqlResult<Order>();
	const surchargeFeePercent = useAtomValue( surchargeFeeAtom );
	const [ tipPercent, setTipPercent ] = useState( 0 );
	const [ dollarTip, setDollarTip ] = useState( 0 );
	const [ showTipOptions, setShowTipOptions ] = useState( true );
	const [ paymentAmount, setPaymentAmount ] = useState( 0 );
	const [ selectedTipOption, setSelectedTipOption ] = useState( 4 );
	const [ select, setSelect ] = useState( 'full' );
	const [ selectedScheduledPayments, setSelectedScheduledPayments ] = useState( {} as Record<string, number> );
	
	const gateway = invoice.gateway || invoice.companyLocation?.gateway;
	const cloverGateway = gateway?.external === 'CLOVER' ? gateway : null;
	const grandTotal = invoice.grandTotal || 0;
	const subTotal = invoice.subTotal || 0;
	const taxTotal = invoice.taxTotal;
	
	const surchargePercent = ( method === 'card' || method?.includes( 'saved' ) ) && cardType !== 'debit'
		? surchargeFeePercent || 0
		: 0;
	const cardFee = surchargePercent > 0 ? 0 : getCardFeeAmount( invoice, method, cardType );
	const paidCardFee = invoice.payments?.reduce( ( sum,
		payment ) => sum.plus( payment?.fee || 0 ), new BigNumber( 0 ) ) || 0;
	const cashDiscountPercent = getCashDiscountPercent( invoice, invoice.company, toLower( method ) );
	const totals = getOrderTotalDiscountAndFees( invoice );
	const totalDiscount = new BigNumber( totals.totalDiscount );
	const totalFees = new BigNumber( totals.totalFees );
	const additionalPrices = totalDiscount.plus( totalFees.minus( paidCardFee || 0 ) ).toNumber();
	const remaining = grandTotal - ( invoice.paidTotal || 0 );
	
	const finalRemaining = !cashDiscountPercent
		? remaining
		: getPayingTotalAfterCashDiscount( invoice, cashDiscountPercent ).orderGrandTotal;
	
	const allScheduledPayments = getAllScheduledPaymentsWithProperAmounts<ScheduledPayment>( invoice.scheduledPayments, grandTotal, finalRemaining )
		.filter( ( payment ) => payment.status === 'SCHEDULED' );
	
	const partialPaying = useMemo( () => {
		if ( allScheduledPayments.length ) return undefined;
		
		switch ( select ) {
			case '$':
				return round( clamp( paymentAmount, 0, finalRemaining ), 2 );
			case '%':
				return round( clamp( finalRemaining * paymentAmount / 100, 0, finalRemaining ), 2 );
			case 'full':
				return round( finalRemaining, 2 );
		}
	}, [ select, paymentAmount, finalRemaining ] );
	
	const combinedScheduledAmount = useMemo( () => {
		if ( !allScheduledPayments.length ) return 0;
		
		// Get all selected scheduled payments
		const selectedPayments = allScheduledPayments.filter( ( payment ) => selectedScheduledPayments[ payment.id ] );
		
		if ( selectedPayments.length === 0 ) return 0;
		// Otherwise calculate normally
		return round( selectedPayments.reduce( ( sum, payment ) => sum + ( payment.amount || 0 ), 0 ), 2 );
	}, [ selectedScheduledPayments, allScheduledPayments, finalRemaining, grandTotal ] );
	
	const amountToPay = partialPaying || combinedScheduledAmount;
	
	const totalBeforeTax = new BigNumber( subTotal ).plus( additionalPrices );
	
	const paying = Math.min( finalRemaining, amountToPay );
	
	// payingBeforeTax = paying - taxTotal * paying / (subTotal + taxTotal)
	const payingBeforeTax = new BigNumber( paying ).minus( new BigNumber( taxTotal ).times( paying )
		.div( totalBeforeTax.plus( taxTotal ) ) );
	
	const cardFeeAmount = payingBeforeTax.times( cardFee ).decimalPlaces( 2 ).toNumber();
	const finalPaying = new BigNumber( paying ).plus( cardFeeAmount ).decimalPlaces( 2 ).toNumber();
	
	const showTips = invoice.company.metadata?.hideTips ? false : invoice.metadata.hasOwnProperty( 'hideTips' )
		? !invoice.metadata?.hideTips
		: !invoice.company.metadata?.hideTips;
	const tipAmount = dollarTip || tipPercent * paying / 100 || 0;
	const finalPayingWithSurcharge = finalPaying + finalPaying * ( surchargePercent / 100 ) + tipAmount;
	
	// if invoice metatdata has enablePartialPayment, use that value, otherwise use company metadata
	const enablePartialPayment = invoice.metadata.hasOwnProperty( 'enablePartialPayment' )
		? !invoice.metadata?.enablePartialPayment
		: !invoice.company?.metadata?.enablePartialPayment;
	
	console.debug( {
		additionalPrices,
		grandTotal,
		subTotal,
		totalFees           : totalFees.toNumber(),
		subTotalWithDiscount: totalBeforeTax.toNumber(),
		taxTotal,
		partialPaying,
		scheduledPaying     : combinedScheduledAmount,
		amountToPay,
		finalRemaining,
		paying,
		payingBeforeTax     : payingBeforeTax.toNumber(),
		cardFeeAmount,
		finalPaying,
		totalDiscount       : totalDiscount.toNumber(),
	} );
	
	return (
		<Fragment>
			{allScheduledPayments.length ? (
				<Stack spacing={1}>
					<Stack direction='column'>
						{allScheduledPayments.map( ( payment ) => (
							<ListItemButton
								key={payment.id}
								onClick={() => {
									const newSelectedScheduledPayments = { ...selectedScheduledPayments };
									if ( newSelectedScheduledPayments[ payment.id ] ) {
										delete newSelectedScheduledPayments[ payment.id ];
									} else {
										newSelectedScheduledPayments[ payment.id ] = payment.amount as number;
									}
									setSelectedScheduledPayments( newSelectedScheduledPayments );
								}}>
								<CustomCheckBox
									checked={Boolean( selectedScheduledPayments[ payment.id ] ) ?? false}
									sx={{ alignSelf: 'start', mt: 0.5 }}
								/>
								<ListItemText
									primary={`${currencyFormat( payment.amount )} (${round( payment.percent || 0, 2 )}% ${cardFee <= 0
										? 'Based Off Grand Total'
										: ''}) ${payment?.reason || ''}`}
									secondary={`${t( 'common:due-on' )} ${safeFormatInTimeZone( payment.dueDate, 'PP' )}`}
								/>
							</ListItemButton>
						) )}
					</Stack>
					<Divider sx={{ mt: 2 }}/>
				</Stack>
			) : enablePartialPayment ? (
				<Stack spacing={1}>
					<Stack direction='row' alignItems='center' spacing={1}>
						<Select
							sx={{ width: 100 }}
							value={select}
							size='small'
							onChange={( e ) => setSelect( e.target.value )}>
							<MenuItem value='$'>$</MenuItem>
							<MenuItem value='%'>%</MenuItem>
							<MenuItem value='full'>{t( 'commerce:full' )}</MenuItem>
						</Select>
						<FormattedTextField
							fullWidth
							disabled={select === 'full'}
							value={select === 'full' ? currencyFormat( finalRemaining ) : paymentAmount}
							variant='outlined'
							placeholder={t( 'common:amount' )}
							onChange={( e ) => setPaymentAmount( +e.target.value || 0 )}
							onFocus={( e ) => e.target.select()}
						/>
					</Stack>
					<Divider sx={{ mt: 2 }}/>
				</Stack>
			) : null}
			{surchargePercent > 0 ? (
				<ListItem disableGutters>
					<ListItemText primary={`There is a surcharge of ${surchargePercent}%`}/>
					<Typography style={{ fontSize: 18 }}>
						{currencyFormat( finalPaying * ( surchargePercent / 100 ) )}
					</Typography>
				</ListItem>
			) : null}
			<ListItem disableGutters>
				<ListItemText primary={t( 'common:total' )}/>
				<Typography style={{ fontSize: 18 }}>
					{currencyFormat( finalRemaining )}
				</Typography>
			</ListItem>
			<Collapse in={surchargePercent <= 0 && cardFee > 0}>
				<ListItem disableGutters>
					<ListItemText primary='Card Fee'/>
					<Typography style={{ fontSize: 18 }}>
						{currencyFormat( cardFeeAmount )}
					</Typography>
				</ListItem>
			</Collapse>
			<Collapse in={showTips}>
				<ListItem disableGutters>
					<ListItemText primary={t( 'common:tip' )}/>
					<Typography color='success.main' style={{ fontSize: 18 }}>
						{currencyFormat( dollarTip || tipPercent * paying / 100 )}
					</Typography>
				</ListItem>
			</Collapse>
			<ListItem disableGutters>
				<ListItemText primary={t( 'common:paying' )}/>
				<Typography variant='h3'>
					{currencyFormat( finalPayingWithSurcharge )}
				</Typography>
			</ListItem>
			<Collapse in={paying !== finalRemaining}>
				<ListItem disableGutters>
					<ListItemText primary={t( 'common:remaining' )}/>
					<Typography color='warning.main' style={{ fontSize: 18 }}>
						{finalRemaining - paying > 0 ? currencyFormat( finalRemaining - paying ) : '$0.00'}
					</Typography>
				</ListItem>
			</Collapse>
			<TipsSection
				cloverGateway={cloverGateway}
				paying={paying}
				showTips={showTips}
				showTipOptions={showTipOptions}
				setShowTipOptions={setShowTipOptions}
				tipPercent={tipPercent}
				setTipPercent={setTipPercent}
				dollarTip={dollarTip}
				setDollarTip={setDollarTip}
				selectedTipOption={selectedTipOption}
				setSelectedTipOption={setSelectedTipOption}
			/>
			<Stack spacing={2} direction='row' alignItems='center' mt={2}>
				<AsyncLoadingButton
					variant='outlined'
					startIcon={<ArrowBackIosIcon/>}
					onClick={cancelAmount}>
					Back
				</AsyncLoadingButton>
				<AsyncLoadingButton
					variant='contained'
					color='primary'
					disabled={!finalRemaining || !paying}
					onClick={async () =>
						confirmTotal( {
							total                    : finalPaying,
							cardFee                  : !invoice.metadata?.cardFee ? cardFeeAmount : 0,
							tip                      : tipPercent * paying / 100,
							dollarTip                : dollarTip || tipPercent * paying / 100,
							finalPayingWithSurcharge,
							selectedScheduledPayments: selectedScheduledPayments,
						} )
					}>
					Continue
				</AsyncLoadingButton>
			</Stack>
		</Fragment>
	);
}
